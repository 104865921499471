body {
  font-family: 'Montserrat', sans-serif;
}

.creat-own-eternaview {
  background-color: #ffc505 !important;
  border-radius: 11px !important;
  color: #2349b4 !important;
  float: left;
  padding: 10px 15.1px;
  margin-left: 68px;
  cursor: pointer;
}
.interact_screen_section {
  /*   height: 528px;
 */
  height: auto;

  position: relative;
}
.iinteract-img-ii img {
  margin-bottom: 30px;
}
.Puchase_faq_btn {
  border-width: 0px 5px 5px 0px;
  border-color: transparent #ce4fff #b730eb transparent;
  background-clip: padding-box;
  margin: 15px 0px;
  background: linear-gradient(180deg, #d186ef, #962fbe);
  font-weight: 500;
  width: 100%;
  color: #fff;
  font-size: 16px;
  border-radius: 50px;
  padding: 9px;
  width: 180px;
}
button.Puchase_faq_btn.pricing_faq {
  position: absolute;
  right: -10px;
  top: -20px;
  font-size: 10px;
  width: 54px;
  height: 54px;
  margin: 0px;
  border-radius: 100%;
  padding: 15px 5px;
  line-height: 13px;
  font-weight: 700;
  text-align: center;
}
.pricing_inner_new {
  background-color: #f6e2ff;
  padding: 30px;
  border-radius: 30px;
  text-align: center;
  margin-bottom: 25px;
  position: relative;
}
.interact-img {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.interact-img img {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
}
p.com_title {
  font-weight: 600;
  text-align: center;
}
.pricing_main_toggle_title {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  font-weight: 700;
  margin-bottom: 12px;
  margin-top: 25px;
}
.click_view, .click_view:hover {
  cursor: pointer;
  color: #921fc4 !important;
  font-weight: 600;
  text-decoration: underline !important;
}
.pricing_new_ul li img {
  width: 20px;
  margin-right: 7px;
  margin-top: 2px;
}
.pricing_new_ul li {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
}
.pricing_new_ul {
  width: 80%;
  margin: 0 auto;
  text-align: left;
}
.pricing_inner_new p {
  margin-bottom: 2px;
}


.Puchase_now_btn {
  border-width: 0px 5px 5px 0px;
  border-color: transparent #02ef80 #03ed6d transparent;
  background-clip: padding-box;
  margin: 15px 0px;
  background: linear-gradient(180deg, #00a54a, #37d298);
  font-weight: 500;
  width: 100%;
  color: #fff;
  font-size: 16px;
  border-radius: 50px;
  padding: 9px;
  width: 180px;
}
.standard_plan {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}
/* Uncomment */
.video-js.vjs-default-skin.custom_video_js {
  width: 87% !important;
}
.thumb_a.light_thumb {
  display: inline-block;
  position: relative;
}
.thumb_a.light_thumb .custom_light {
  bottom: -50px;
  width: 200px;
  margin: 0px;
  z-index: 9;
}
.interact_screen_section.portrait_video_outer .topic_div {
  right: 10px;
  /* top: 80px;
  bottom: inherit; */
  z-index: 9;
  background-color: #f7f7f7;
}
.interact_screen_section.portrait_video_outer_practice .topic_div {
  right: 10px;
  /* top: 45px;
  bottom: inherit; */
 z-index: 9;
  background-color: #f7f7f7;
}
.card .mb-0 .btn {
  white-space: inherit;
}
.topic_btn {
  background-color: #2349b4;
  display: inline-block;
  padding: 8px 20px;
  color: #fff !important;
  border-radius: 10px;
  transition: 0.3s;
}

.thumb_icon_div {
  text-align: right;
  margin-top: 15px;
}

.thumb_a img {
  width: 50px;
}

.submit_retake_btndiv a {
  background-color: #1dbf73;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 30px;
  margin-right: 5px;
}

.ask_question_popup {
  text-align: center;
  margin-bottom: 10px;
}

.ask_question_popup p {
  background-color: #0000005c;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  padding: 10px 5px;
}

a.retake_b {
  background-color: #f30a21;
}

.row.mobile_row {
  display: none;
}

.start_question_div {
  text-align: center;
}

.create_video_div {
  text-align: center;
  margin-bottom: 20px;
}
a.hand_img.take_break_handimg img {
  height: 75px;
}
.topic_div::-webkit-scrollbar {
  width: 5px;
}

.topic_div::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.topic_div::-webkit-scrollbar-thumb {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: #ffc505;
}

.create_intro_section {
  padding: 10px 0px 0px 0px;
}

.create_video_inner {
  width: 500px;
  height: 282px;
  margin: 0 auto;
  position: relative;
  background-color: #000;
}

.submit_retake_btndiv {
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 9;
}

.recording_p {
  width: 340px;
  margin: 0px;
  font-weight: 500;
  color: #0a0a0a;
}

.hand_div_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;
}

.create_video {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 9px 0px #000;
}

.start_recording_btn {
  background-color: #1dbf73;
  width: 300px;
  color: #fff;
  font-size: 18px;
  border-radius: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 12px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.3s;
}

.start_recording_btn:hover {
  background-color: #1c1c1c;
  color: #fff;
  text-decoration: none;
}

.name_questionn {
  color: #921fc4;
  font-weight: 700;
  border: 1px solid #ccc;
  width: 100%;
  padding: 10px;
  font-size: 18px;
}

.skip_module {
  color: #921fc4;
  text-decoration: underline;
  font-weight: 500;
}

.interact_video {
  width: 100%;
  height: 528px;
}

.interact_buttons_div {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;

}

.interact_buttons_divPotrait{
  position: absolute;
  bottom: 10px;
  width: 100%;

}

.click_talk_btn:hover,
.topic_btn:hover,
.keyboard_icon:hover {
  text-decoration: none;
  color: #ffc505 !important;
}

.interact_btn_right {
  text-align: right;
}

.topic_div .card-body {
  padding: 10px;
  text-align: left;
}

.topic_div .card {
  border-radius: 10px;
}

.topic_div .bs-example {
  margin-top: 5px;
}

.topic_close {
  width: 24px;
  height: 24px;
  position: sticky;
  background-color: #2349b4 !important;
  z-index: 9999;
  border-radius: 100%;
  text-align: center;
  line-height: 23px !important;
  color: #fff !important;
  font-size: 14px;
  right: 0px;
  cursor: pointer;
  display: inline-block;
  top: 0;
}

span.span_tooltip.tips-button-interact.quick_tip {
  position: inherit;
  margin-right: 10px;
}

.title_interact_voice {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;
  color: #2349b4;
  font-family: 'Archivo Black';
}

.nootic_div {
  background-color: #ffc505;
  margin-top: 20px;
  padding: 10px 15px;
  border-radius: 10px;
}

.record_time {
  color: #1dbf73;
  font-weight: 500;
}

.interact_btn_left {
  position: relative;
}

.interact_btn_left,
.interact_btn_center,
.interact_btn_right {
  width: 50.3%;
}

.interact_btn_center {
  text-align: center;
}

.record_title {
  margin-bottom: 0px;
  font-size: 16px;
  color: #2349b4;
  font-family: 'Roboto';
}

.interact_ul {
  margin-bottom: 30px;
}

.begin_interact_title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #2349b4;
  font-family: 'Archivo Black';
}

.interact_ul li i {
  color: #921fc4;
  margin-right: 10px;
  font-size: 18px;
  margin-top: 6px;
}

.interact_ul li {
  color: #2349b4;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  font-family: 'Roboto';
}

.intract_voice_section {
  padding: 50px 0px;
}

.topic_div .card-header {
  padding: 0px;
}

.topic_div {
  position: absolute;
  width: 370px;
  height: 450px;
  overflow-y: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  /* display: none; */
  right: -2%;
  bottom: 40px;
}


.click_talk_btn::after {
  position: absolute;
  content: "";
  width: 185px;
  height: 90px;
  z-index: 1;
  bottom: -6px;
  border-radius: 120px 120px 10px 10px;
  z-index: 9;
  left: 38px;
}

.click_talk_btn.bg_hover::after {
  background-color: rgba(175, 32, 39, 0.3);
  /* background-color: rgba(146, 31, 196, 0.3); */


}

.custom_accordion_button {
  text-align: left;
  border: none;
  background-color: transparent;
  padding: 10px 12px;
  border-bottom: 1px solid #2349b4;
  width: 100%;
}

.click_talk_btn_recording{
  background-color: #e6061f !important
}

.click_talk_btn {
  background-color: #2349b4;
  display: inline-block;
  padding: 7px 15px 7px 10px;
  color: #fff;
  margin-left: 7px;
  border-radius: 10px;
}
.custom_accordion_button {
  font-size: 13px;
  color: #2349b4;
}
.ask_question_div {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  margin-bottom: 10px;
  /* display: none; */
  position: absolute;
  bottom: 40px;
  z-index: 99;
}

.ask_question_div input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px;
  /* font-size: 12px; */
  border-left: 5px solid #2349b4;
}

.click_talk_btn i {
  width: 32px;
  height: 32px;
  background-color: #e91313;
  text-align: center;
  line-height: 32px;
  margin-right: 6px;
  border-radius: 100%;
}

.interact_buttons_inner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 10px;
  /* padding: 0px 15px; */
  width: 87%;
  margin: 0 auto;
}
.thumb_icon_div_inner {
  width: 87%;
  margin: 0 auto;
}

.keyboard_icon {
  width: 44px;
  height: 44px;
  display: inline-block;
  background-color: #2349b4;
  text-align: center;
  line-height: 44px;
  color: #fff !important;
  border-radius: 100%;
}


@media screen and (max-width: 1199px) {
.row.mobile_row {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pricing_new_ul {
  width: 100%;
  margin: 0 auto 15px auto;
}
a.hand_img.take_break_handimg img {
  height: auto;
}
.begin_interact_title {
  font-size: 20px;
  margin-bottom: 0px;
}
.start_recording_btn {
  width: 500px;
  padding: 10px;
}
.row.mobile_row .col-sm-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-md-4.empty_col {
  display: none;
}
.create_intro_section .col-md-4.col-sm-6.text-center.left_fourcol {
  flex: 0 0 70%;
  text-align: left !important;
  max-width: 70%;
}

.create_intro_section .col-md-4.col-sm-6.text-right.right_fourcol {
  flex: 0 0 30%;
  text-align: right !important;
  max-width: 30%;
}
.name_questionn {
  font-size: 16px;/* .interact_buttons_inner {
    width: 100%;
  } */
  max-width: 100%;
  text-align: center;
}
.click_talk_btn::after {
  width: 190px;
  height: 90px;
  left: 36px;
}
}
/* Uncomment after pushing to live  */

/* @media screen and (max-width: 1024px) {
  section.interact_screen_section.portrait_video_outer .video-js.vjs-default-skin.custom_video_js {
    width: 100% !important;
  }
  .video-js.vjs-default-skin.custom_video_js {
    width: 85% !important;
  }
  section.interact_screen_section.portrait_video_outer .video-js.vjs-default-skin.custom_video_js {
    width: 100% !important;
  }
  .video-js.vjs-default-skin.custom_video_js {
    width: 85% !important;
  }
} */

@media screen and (max-width: 991px) {
    .video-js.vjs-default-skin.custom_video_js {
  width: 100% !important;
}
  .container.interact_btn_container {
    max-width: 100%;
  }
  .thumb_icon_div {
    width: 100%;
    margin-top: 40px;
}
.interact_btn_left, .interact_btn_center, .interact_btn_right {
  width: 42.3%;
}
.col-md-4.custom_four_col {
  max-width: 80% !important;
  flex: 0 0 80% !important;
}
.pricing_new_ul {
  width: 100%;
  margin: 0 auto 15px auto;
}
  .interact_buttons_inner {
    width: 100%;
  } 
  .thumb_icon_div_inner {
    width: 100%;
  }
  .create_video_div.portrait_create_section .create_video_inner {
    height: 80vh;
    background-color: #000;
  }

  .interact_screen_section.portrait_video_outer {
    height: auto;
  }
  .interact_screen_section.portrait_video_outer_practice {
    height: auto;
  }

  .card .fa {
    font-size: 16px;
  }



  .interact_screen_section.portrait_video_outer .interact_buttons_div {
    position: inherit;
    bottom: inherit;
    margin-top: 10px;
  }
  .interact_screen_section.portrait_video_outer_practice .interact_buttons_div {
    position: inherit;
    bottom: inherit;
    margin-top: 10px;
  }

  .ask_question_popup {
    min-height: 30px;
}

  .interact_screen_section.portrait_video_outer .interact_buttons_divPotrait{
    position: absolute;
    bottom: 5px;
    width: 100%;
  }

  .interact_screen_section.portrait_video_outer_practice .interact_buttons_divPotrait{
    position: absolute;
    bottom: 5px;
    width: 100%;
  }

  /* .interact_buttons_div {
  position: inherit;
  bottom: inherit;
} */
  .ask_question_popup p {
    font-size: 12px;
    padding: 5px 5px;
  }

  .hand_div_section {
    margin-top: 0px;
  }

  .intract_voice_section {
    padding: 20px 0px;
  }

  .col-md-6.custom_six_col {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .content {
    padding-top: 0px;
  }

  .interact_video {
    height: 330px;
  }

  .interact_screen_section .myVideo-dimensions.vjs-fluid {
    height: 330px !important;
  }

  .interact_screen_section {
    height: 330px;
  }
  .interact-img {
    height: 330px;
}
.interact_screen_section.portrait_video_outer .topic_div {
  height: 350px;
  width: 250px;
}
  .topic_div {
    height: 250px;
    width: 250px;
  }

  .topic_btn {
    padding: 6px 10px;
    font-size: 14px;
  }

  .ask_question_div input {
    padding: 8px;
    /* font-size: 13px; */
  }

  .click_talk_btn {
    padding: 5px 10px;
    font-size: 14px;
  }

  .keyboard_icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .click_talk_btn::after {
    width: 148px;
  }

  .click_talk_btn i {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }

  .thumb_a img {
    width: 35px;
  }
  span.span_tooltip.tips-button-interact.quick_tip {
    padding: 3px 10px;
    font-size: 11px;
  }

  span.span_tooltip.tips-button-interact.quick_tip img {
    height: 15px;
    width: 20px;
  }

  .creat-own-eternaview {
    padding: 5px 13.1px;
    font-size: 11px;
    margin-left: 0;
    font-weight: 600;
  }
}




/* Iphone Landscape */
@media screen and (max-width: 767px) {
  .keyboard_icon {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
  .start_recording_btn {
    width: 300px;
  }
  .hand_img.take_break_handimg {
    right: 0;
    left: auto;
  }

  .hand_div_section {
    display: block;
    position: relative;
    padding-bottom: 60px;
    margin: 0 !important;
  }

  .hand_img {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .interact_buttons_inner, .thumb_icon_div_inner {
    padding: 0px 5px;
  }

  .click_talk_btn {
    font-size: 13px;
  }

  .content {
    padding-top: 60px;
  }

  .submit_retake_btndiv a {
    padding: 3px 10px;
    font-size: 12px;
  }

  .recording_p {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 5px;
  }

  .hand_div_section {
    margin-top: -15px;
  }

  .hand_img img {
    width: 50px;
  }

  .row.align-items-center.desktop_row .col-md-3 {
    display: none;
  }

  .row.mobile_row {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .row.mobile_row .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .create_intro_section .col-md-4.col-sm-6.text-center {
    flex: 0 0 50%;
    text-align: left !important;
  }

  .create_intro_section .col-md-4.col-sm-6.text-right {
    flex: 0 0 50%;
    text-align: right !important;
  }

  .create_intro_section .col-md-3,
  .create_intro_section .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }

  .create_intro_section .col-md-3.text-right {
    text-align: center !important;
  }

  .interact_ul li {
    margin-bottom: 7px;
    font-size: 15px;
  }

  .interact_video {
    height: 320px;
  }

  .interact_screen_section {
    height: auto;

  }

  /* Uncomment */

  /* section.interact_screen_section.portrait_video_outer .video-js.vjs-default-skin.custom_video_js {
    width: 100% !important;
  } */

  /* Uncomment */

  /* .video-js.vjs-default-skin.custom_video_js {
    width: 85% !important;
  } */

  .myVideo-dimensions.vjs-fluid {
    height: 319px !important;
  }

  .interact_ul li i {
    margin-right: 7px;
    font-size: 15px;
    margin-top: 6px;
  }

  .record_title {
    font-size: 15px;
  }

  .record_time {
    font-size: 15px;
  }

  .begin_interact_title {
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 26px;
  }

  .start_recording_btn {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
  }

  .create_intro_section {
    padding: 6px 0px 0px 0px;
  }

  .name_questionn {
    padding: 5px;
    font-size: 15px;
  }

  .click_talk_btn i {
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin-right: 4px;
  }

  .interact_btn_left {
    display: flex;
    align-items: center;
    width: 300px;
  }

  .click_talk_btn::after {
    width: 155px;
    height: 70px;
    bottom: -6px;
    left: 25px;
  }

  .ask_question_div input {
    /* font-size: 13px; */
  }

  .ask_question_div {
    width: 190px;
  }

  .topic_btn {
    padding: 6.5px 10px;
    font-size: 14px;
  }

  .click_talk_btn {
    padding: 3.5px 10px;
    margin-left: 4px;
    border-radius: 10px;
}
}


@media screen and (max-width: 575px) {
  .create_video_inner {
    width: 100%;
    height: 270px;
    margin: 0 auto 10px auto;
    background-color: #000;
  }

  .ask_question_popup p {
    max-width: inherit;
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
.start_recording_btn {
  width: 100%;
}
}

@media (max-width: 926px) and (orientation: landscape) {
  .topic_div {
    right: 0%;
  }
}