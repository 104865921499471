/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .video-js.vjs-default-skin.custom_video_js {
        width: 520px !important;
    }
    .interact_screen_section.portrait_video_outer .interact_buttons_divPotrait {
        width: 520px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .thumb_icon_div {
        width: 550px;
        margin-left: auto;
        margin-right: auto;
    }
    .interact_btn_center {
        width: 80px;
    }
    .interact_btn_left {
        width: 50%;
    }
}
@media only screen and (min-width: 360px) and (max-width: 880px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .video-js.vjs-default-skin.custom_video_js {
        width: 100% !important;
    }
    /* section.interact_screen_section.portrait_video_outer .video-js.vjs-default-skin.custom_video_js {
        width: 100% !important;
      }
      .video-js.vjs-default-skin.custom_video_js {
        width: 85% !important;
      }
      .interact_buttons_div .container.interact_btn_container11 {
        max-width: 71%;
    }
    section.interact_screen_section.portrait_video_outer .interact_buttons_div .container.interact_btn_container11 {
        max-width: 100%;
    } */
}
/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 840px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .video-js.vjs-default-skin.custom_video_js {
        width: 100% !important;
    }
    section.interact_screen_section.portrait_video_outer .video-js.vjs-default-skin.custom_video_js {
        width: 100% !important;
      }
      .video-js.vjs-default-skin.custom_video_js {
        /* width: 85% !important; */
      }
      .interact_buttons_div .container.interact_btn_container11 {
        max-width: 75%;
    }
    section.interact_screen_section.portrait_video_outer .interact_buttons_div .container.interact_btn_container11 {
        max-width: 100%;
    }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .video-js.vjs-default-skin.custom_video_js {
        width: 100% !important;
    }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 834px) and (max-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .video-js.vjs-default-skin.custom_video_js {
        width: 100% !important;
    }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .video-js.vjs-default-skin.custom_video_js {
        width: 100% !important;
    }
}